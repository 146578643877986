import { FC } from 'react'
import { Icon } from 'components/icon'
import { colors } from 'utils/colors'
import { Button } from 'components/button/Button'
import { useTranslation } from 'next-i18next'
import { SBody, SFooter, SHeading, SModal, SText, STitle } from './EmailVerificationModal.styled'
import { sendVerificationEmail } from 'utils/email'
import { getSessionValue } from 'utils/sessionStorage'
import { sessionStorageEmailKey } from 'components/auth/register/handleSignupSuccess'

type EmailVerificationModalProps = {
  closeModal: () => void
}

export const EmailVerificationModal: FC<EmailVerificationModalProps> = ({ closeModal }) => {
  const { t } = useTranslation('auth')

  const email = getSessionValue(sessionStorageEmailKey)
  const sendEmail = async () => {
    await sendVerificationEmail(email, closeModal)
  }

  return (
    <SModal>
      <SHeading>
        <Icon name="Mail" size="3rem" color={colors.black} />
        <STitle>{t('auth.verify_email_modal.title')}</STitle>
      </SHeading>
      <SBody>
        <SText>{t('auth.verify_email_modal.text')}</SText>
      </SBody>
      <SFooter>
        <Button type="button" leftIcon={<Icon name="Send" />} onClick={sendEmail}>
          {t('auth.verify_email_modal.button_text')}
        </Button>
      </SFooter>
    </SModal>
  )
}
