import { LifeStoriesContext } from 'components/content/lifeStories/LifeStoriesContext'
import { Trans, useTranslation } from 'next-i18next'
import { useContext } from 'react'
import { LinkVariant, TranslatableLink } from './TranslatableLink'

export function TermsAndPrivacyLinks({ variant = 'basic' }: { variant?: LinkVariant }) {
  const { t } = useTranslation()

  const isInsideLifeStoriesContext = useContext(LifeStoriesContext)
  const withAIPledge = isInsideLifeStoriesContext

  return (
    <Trans
      t={t}
      i18nKey={withAIPledge ? 'common.terms_and_privacy_and_ai_pledge' : 'common.terms_and_privacy'}
      components={[
        <TranslatableLink key="0" href="/terms-of-service" variant={variant} target="_blank" />,
        <TranslatableLink key="1" href="/privacy-policy" variant={variant} target="_blank" />,
        <TranslatableLink
          key="2"
          href="/articles/new-story-portraits-and-goodtrust-ai-ethics-pledge"
          variant={variant}
          target="_blank"
        />,
      ]}
    />
  )
}
