import { css } from '@emotion/react'
import { handleAuthResponse, useMfaToken } from 'api/goodtrust/auth.util'
import { Controller, useForm } from 'react-hook-form'
import { IPinInputValue, PinInput } from 'components/pinInput/PinInput'
import { useLoadingCallback } from 'utils/hooks'
import { postMfaLogin, resendMfaLoginCode } from 'api/goodtrust/auth'
import { handleAndToastError, toastError, toastSuccess } from 'components/Toast'
import { FormValues } from 'utils/types'
import { SButtonContainer, SMfaHeading, SMfaModal } from './AuthModal.styled'
import { Text, Title } from 'components/Typography'
import { FormFieldset } from 'components/FormButton'
import { StateSpinner } from 'components/StateSpinner'
import { pxToRem } from 'utils/styled'
import { PrimaryLink } from 'components/Link'
import { Button } from 'components/button/Button'
import { Spacer } from 'components/spacer/Spacer'
import { Icon } from 'components/icon'
import { MessageException } from 'utils/error'
import { unwrapResponse } from 'utils/fetcher'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'
import { Trans } from 'next-i18next'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { BlockSpinner } from 'components/spinner/Spinner'
import { PIN_LENGTH } from 'api/goodtrust/user'
import { noop } from 'utils/general'

export const MfaModal = (props: {
  onBack: () => void
  onLogin?: () => void
  hideBack?: boolean
}) => {
  const { t, isLoading } = useDynamicTranslations('auth')

  const validation = useFieldValidation()
  const mfa = useMfaToken()
  const mfaToken = mfa.data
  const form = useForm<{ pin: IPinInputValue }>()
  const [resendCode, isLoadingResend] = useLoadingCallback(async () => {
    try {
      if (!mfaToken) {
        throw new MessageException('invalid_token')
      }
      const res = await resendMfaLoginCode(mfaToken).then(unwrapResponse)
      toastSuccess('six_digit_code_resent')
      // it seems that the backend returns a new token, which we should save and use next time
      // the old token seems to be invalidated by the resent action
      mfa.mutate(res.json?.mfa_token)
    } catch (err) {
      handleAndToastError(err, 'failed_to_send_verification_code')
    }
  })
  const handleSubmit = async (data: FormValues<typeof form>) => {
    const code = data.pin.filter(Boolean).join('')
    if (code.length !== PIN_LENGTH) return
    if (mfaToken == null) {
      toastError('invalid_token')
      return
    }
    let authResult
    try {
      authResult = await handleAuthResponse(await postMfaLogin(code, mfaToken), false, {
        errorMessage: 'Invalid code',
        onVerificationRequired: noop,
      })
    } catch (err) {
      handleAndToastError(err, 'failed_to_login')
      return
    }
    const isSuccessful = !!authResult
    if (isSuccessful && props.onLogin) {
      props.onLogin()
    }
  }

  if (isLoading) return <BlockSpinner />
  return (
    <SMfaModal>
      <SMfaHeading>
        <Icon name="LockClosed" />
        <Title variant={'h6'} as="h6">
          {t('auth.mfa_modal.title')}
        </Title>
      </SMfaHeading>
      <Text>{t('auth.mfa_modal.text')}</Text>
      <form onSubmit={form.handleSubmit(handleSubmit)}>
        <Controller
          name="pin"
          control={form.control}
          rules={validation.incompleteCode()}
          render={({ field }) => (
            <FormFieldset form={form} name={field.name}>
              <PinInput value={field.value} onChange={field.onChange} />
            </FormFieldset>
          )}
        />
        <StateSpinner isLoading={isLoadingResend}>
          <Text
            css={css`
              margin-top: ${pxToRem(20)}rem;
            `}
          >
            <Trans
              t={t}
              i18nKey="auth.mfa_modal.code_not_received"
              components={[<PrimaryLink onClick={resendCode} key={0} />]}
            />
          </Text>
        </StateSpinner>
        <SButtonContainer>
          {!props.hideBack && (
            <>
              <Button size="large" variant="secondary" type="button" onClick={props.onBack}>
                {t('auth.mfa_modal.back_button')}
              </Button>
              <Spacer size={1} axis={'horizontal'} />
            </>
          )}
          <Button size="large" type="submit" loading={form.formState.isSubmitting}>
            {t('auth.mfa_modal.submit_button')}
          </Button>
        </SButtonContainer>
      </form>
    </SMfaModal>
  )
}
