import { toastError, toastSuccess } from 'components/Toast'
import { postResendVerifyEmail } from 'api/goodtrust/user'
import { css } from '@emotion/react'
import { BREAKPOINTS } from 'utils/styled'

export const sendVerificationEmail = async (email: string | null, callback?: () => void) => {
  if (!email) {
    toastError('failed_to_send_verification_email')
    callback?.()
    return
  }

  try {
    const res = await postResendVerifyEmail({ email })
    if (res.ok) {
      toastSuccess('verification_email_resent')
    } else {
      toastError('failed_to_send_verification_email')
    }
  } catch (err) {
    toastError('failed_to_send_verification_email')
  } finally {
    callback?.()
  }
}

export const emailModalConfig = {
  modalContentCss: css`
    padding: 2rem;
    max-width: 580px;
    @media ${BREAKPOINTS.MD.max} {
      padding-bottom: 4rem;
    }
  `,
}
