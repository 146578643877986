import { apiFetcher } from './api'
import { ApiType } from 'utils/types'

export function postGoogle(token: string) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/google', {
    method: 'POST',
    headers: {
      token,
    },
  })
}

export function postFacebook(token: string) {
  return apiFetcher<ApiType['UserLoginResponse']>('/api/v1/facebook', {
    method: 'POST',
    headers: {
      token,
    },
  })
}
