import { css } from '@emotion/react'
import { BREAKPOINTS, pxToRem } from 'utils/styled'
import { Fieldset } from 'components/fieldset/Fieldset'
import { useEffect, useRef } from 'react'
import { PIN_LENGTH } from 'api/goodtrust/user'

const VALID_VALUES = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9']

export type IPinInputValue = (string | null)[]

export function PinInput(props: {
  value?: IPinInputValue
  onChange: (value: IPinInputValue) => void
}) {
  const refs = useRef<(HTMLInputElement | null)[]>(Array(6).fill(null))
  const refLength = props.value?.length ?? PIN_LENGTH

  const handleValueChange = (value: string, key: number) => {
    if (key < 0 || key >= refLength) return

    if (value === '') {
      const newValue = [...(props.value ?? Array(refLength).fill(null))]
      newValue[key] = null
      props.onChange(newValue)
      return
    }

    if (!VALID_VALUES.includes(value)) return
    const nextInput = refs.current[key + 1]
    if (nextInput != null) {
      nextInput.focus()

      if (props.value?.[key + 1]) {
        nextInput.setSelectionRange(0, 1)
      }
    }

    const newValue = [...(props.value ?? Array(refLength).fill(null))]
    newValue[key] = value
    props.onChange(newValue)
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLInputElement>, key: number) {
    const currInput = refs.current[key]
    let focusInput: HTMLInputElement | null = null

    if (e.key === 'ArrowLeft') {
      focusInput = refs.current[key - 1] ?? null
    } else if (e.key === 'ArrowRight') {
      focusInput = refs.current[key + 1] ?? null
    }

    if (focusInput != null) {
      e.preventDefault()
      focusInput.focus()
      focusInput.setSelectionRange(0, 1)
    } else if (currInput != null) {
      // handle same key event triggering
      const nextValue = VALID_VALUES.includes(e.key) ? e.key : null
      const currValue = currInput.value

      if (nextValue && nextValue === currValue) {
        // trigger the event nonetheless
        e.preventDefault()
        handleValueChange(nextValue, key)
        return
      }

      if (!currValue && e.key === 'Backspace') {
        // focus and remove the previous value
        e.preventDefault()
        const focusInput = refs.current[key - 1]
        void focusInput?.focus()

        handleValueChange('', key - 1)
        return
      }
    }
  }

  function handlePasteEvent(e: React.ClipboardEvent<HTMLInputElement>, key: number) {
    e.stopPropagation()
    e.preventDefault()

    const clipboard = e.clipboardData?.getData('Text')?.trim()?.slice(0, refLength)

    const oldValue = props.value ?? Array(refLength).fill(null)
    const pasted = (clipboard ?? '')
      .split('')
      .map((value) => (VALID_VALUES.includes(value) ? value : null))

    if (pasted.filter(Boolean).length === refLength) {
      props.onChange(pasted)
      return
    }

    const newValue = oldValue
      .slice(0, key)
      .concat(pasted)
      .concat(oldValue.slice(key + pasted.length))
      .slice(0, refLength)

    while (refLength > newValue.length) {
      newValue.push(null)
    }

    props.onChange(newValue)
  }

  useEffect(() => {
    if (refLength !== refs.current.length) {
      while (refLength > refs.current.length) {
        refs.current.push(null)
      }
      refs.current.length = refLength
    }
  })

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: repeat(${refLength}, 1fr);
        grid-gap: 15px;

        max-width: ${pxToRem(460)}rem;
        position: relative;

        @media ${BREAKPOINTS.MD.max} {
          grid-gap: 5px;
        }
      `}
    >
      {new Array(refLength).fill(null).map((_: null, key: number) => {
        return (
          <Fieldset
            key={key}
            css={css`
              input {
                font-size: ${pxToRem(20)}rem;
                padding: ${pxToRem(20)}rem 0;
                min-width: ${pxToRem(60)}rem;
                text-align: center;
              }

              @media ${BREAKPOINTS.MD.max} {
                input {
                  min-width: ${pxToRem(20)}rem;
                }
              }
            `}
          >
            <input
              type="tel"
              maxLength={1}
              autoComplete="off"
              value={props.value?.[key] ?? ''}
              ref={(el) => (refs.current[key] = el)}
              onChange={(e) => handleValueChange(e.target.value, key)}
              onKeyDown={(e) => handleKeyDown(e, key)}
              onPaste={(e) => handlePasteEvent(e, key)}
            />
          </Fieldset>
        )
      })}
    </div>
  )
}
