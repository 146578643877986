import styled from '@emotion/styled'
import { InputGrid } from 'components/Grid'
import { BREAKPOINTS } from 'utils/styled'

export const SInputGrid = styled(InputGrid)`
  margin-bottom: 1rem;

  @media ${BREAKPOINTS.SM.max} {
    & > * {
      /* grid-column-end: span ${2}; */
    }
  }
`
