import styled from '@emotion/styled'
import { Text } from 'components/Typography'
import { colors } from 'utils/colors'

export const SModal = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;
`
export const SHeading = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  margin-top: 1.5rem;
  gap: 1.5rem;
`
export const STitle = styled(Text)`
  font-size: 1.25rem;
  color: ${colors.black};
`
export const SBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
export const SText = styled(Text)`
  text-align: center;
`
export const SFooter = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
