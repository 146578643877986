import { css } from '@emotion/react'
import { postLogin } from 'api/goodtrust/auth'
import { handleAuthResponse, IAuthResponseOptions, useMfaToken } from 'api/goodtrust/auth.util'
import { postFacebook, postGoogle } from 'api/goodtrust/social'
import { postUserRegister } from 'api/goodtrust/user'
import { AuthSocial } from 'components/auth/authSocial/AuthSocial'
import { Button } from 'components/button/Button'
import { Fieldset } from 'components/fieldset/Fieldset'
import { FormFieldset } from 'components/FormButton'
import { InputGrid } from 'components/Grid'
import { Icon } from 'components/icon'
import { PrimaryLink } from 'components/Link'
import { useModal } from 'components/modal/Modal'
import { PasswordInput } from 'components/passwordInput/PasswordInput'
import { Spacer } from 'components/spacer/Spacer'
import { BlockSpinner } from 'components/spinner/Spinner'
import { TermsAndPrivacyLinks } from 'components/TermsAndPrivacyLinks'
import { toastSuccess } from 'components/Toast'
import { Caption, Text, Title } from 'components/Typography'
import { nanoid } from 'nanoid'
import { Trans, useTranslation } from 'next-i18next'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { KeyedMutator } from 'swr'
import { events, mutateAnalyticsData } from 'utils/analytics'
import { Analytics } from 'utils/analytics/types'
import { useAuth } from 'utils/auth/hooks/useAuth'
import { AuthState } from 'utils/auth/types'
import { logError, logMsg } from 'utils/general'
import { useDynamicTranslations } from 'utils/i18n/useDynamicTranslations'
import { useFieldValidation } from 'utils/i18n/useFieldValidation'
import { IFacebookCallback, IGoogleSuccessCallback, onGoogleFailureToastError } from 'utils/social'
import { BREAKPOINTS, pxToRem, pxToRemUnit } from 'utils/styled'
import { ApiType } from 'utils/types'
import { SAuthHeading, SAuthModal, SButtonContainer, STermsText } from './AuthModal.styled'
import { MfaModal } from './MfaModal'
import { EmailVerificationModal } from 'components/modal/emailVerificationModal/EmailVerificationModal'
import { emailModalConfig } from 'utils/email'

type ModalFields = {
  email: string
  password: string
  first_name: string
  last_name: string
}

export type AuthModalConfig = {
  onlyLogin?: boolean
  loginTitle?: string
  loginDesc?: string
  loginButtonTitle?: string
  signupTitle?: string
  signupDesc?: string
  signupButtonTitle?: string
  source?: ApiType['UserCreateRequest']['source']
  buttonTitle?: string
  analyticsData?: Analytics
  variant?: 'simple' | 'default'
}

const AuthModal = (props: {
  onMfa: () => void
  onLogin?: () => void
  onSignup?: () => void
  defaultEmail?: string
  forceAuth?: boolean
  config?: AuthModalConfig
  mutateAuth: KeyedMutator<NonNullable<AuthState>>
}) => {
  const variant = props.config?.variant ?? 'default'
  const { t, isLoading } = useDynamicTranslations('auth')
  const { t: tCommon } = useTranslation()
  const validation = useFieldValidation()
  const { config } = props

  const [isLogin, setLogin] = useState(config?.onlyLogin ?? false)
  const form = useForm<ModalFields>()
  const mfaToken = useMfaToken()

  const [emailVerificationModal, showEmailVerificationModal] = useModal(
    ({ close, isOpen }) => (isOpen ? <EmailVerificationModal closeModal={close} /> : null),
    emailModalConfig
  )
  const authOpts: IAuthResponseOptions = {
    onVerificationRequired: showEmailVerificationModal,
    onError({ errorCode }) {
      if (errorCode === 'entity_create_user_duplicate') {
        setLogin(true)
      }
    },
    onLoginSuccess(loginStatus) {
      logMsg('Login success with status: ', loginStatus)
      if (loginStatus === 'signup') {
        if (config?.analyticsData) {
          mutateAnalyticsData(config.analyticsData)
        }
        events.user_registered.fire()
        toastSuccess('account_created')
        props.onSignup && props.onSignup()
      } else {
        toastSuccess('logged_in')
        props.onLogin && props.onLogin()
      }
      props.mutateAuth()
    },
    async onMfa(token) {
      await mfaToken.mutate(token)
      props.onMfa()
    },
  }

  const onGoogleSuccess: IGoogleSuccessCallback = async (response) =>
    handleAuthResponse(await postGoogle(response.accessToken), null, authOpts)

  const onFacebook: IFacebookCallback = async (response) => {
    if (!response.accessToken) return
    return handleAuthResponse(await postFacebook(response.accessToken), null, authOpts)
  }

  const handleSubmit = async (payload: ModalFields) => {
    try {
      const password = props.forceAuth ? nanoid(16) : payload.password
      await handleAuthResponse(
        isLogin
          ? await postLogin(payload.email, payload.password)
          : await postUserRegister({
              email: payload.email,
              first_name: payload.first_name,
              last_name: payload.last_name,
              password: password,
              password_plain: props.forceAuth ? password : undefined,
              source: config?.source || 'DEFAULT',
            }),
        !isLogin,
        authOpts
      )
    } catch (e) {
      logError(e)
    }
  }

  if (isLoading) return <BlockSpinner />
  return (
    <>
      {emailVerificationModal}
      <SAuthModal initial={{ scale: 0 }} animate={{ scale: 1 }} transition={{ duration: 0.5 }}>
        <SAuthHeading css={{ paddingTop: variant === 'simple' ? pxToRemUnit(26) : undefined }}>
          <Icon name="ColorGoodTrust" size="3.75rem" />
          <Title variant={'h6'} as="h6">
            {isLogin
              ? config?.loginTitle || t('auth.login_modal.login_title')
              : config?.signupTitle || t('auth.login_modal.signup_title')}
          </Title>
          {variant !== 'simple' && (
            <>
              <Spacer size={1.5} />
              <Text>
                {isLogin
                  ? config?.loginDesc || t('auth.login_modal.login_desc')
                  : config?.signupDesc || t('auth.login_modal.signup_desc')}
              </Text>
            </>
          )}
        </SAuthHeading>

        {variant !== 'simple' && (
          <Caption css={{ marginBottom: '1rem' }}>{t('auth.login_modal.continue_caption')}</Caption>
        )}
        <AuthSocial
          onFacebook={onFacebook}
          onGoogleFailure={(res) => onGoogleFailureToastError(res, 'failed_to_login')}
          onGoogleSuccess={onGoogleSuccess}
        />

        <Caption css={{ margin: '1rem 0' }}>
          {variant === 'simple'
            ? isLogin
              ? t('auth.login_modal.use_email_caption_simple_login')
              : t('auth.login_modal.use_email_caption_simple_signup')
            : t('auth.login_modal.use_email_caption')}
        </Caption>
        <form style={{ position: 'relative' }} onSubmit={form.handleSubmit(handleSubmit)}>
          <InputGrid css={{ marginBottom: '1rem' }}>
            {!isLogin && (
              <>
                <FormFieldset form={form}>
                  <Fieldset css={{ gridColumn: variant === 'simple' ? 'span 1' : undefined }}>
                    <input
                      {...form.register('first_name', validation.required())}
                      type="text"
                      placeholder="&nbsp;"
                    />
                    <label>{t('auth.login_modal.first_name_label')}</label>
                  </Fieldset>
                </FormFieldset>

                <FormFieldset form={form}>
                  <Fieldset css={{ gridColumn: variant === 'simple' ? 'span 1' : undefined }}>
                    <input
                      {...form.register('last_name', validation.required())}
                      type="text"
                      placeholder="&nbsp;"
                    />
                    <label>{t('auth.login_modal.last_name_label')}</label>
                  </Fieldset>
                </FormFieldset>
              </>
            )}

            <FormFieldset form={form}>
              <Fieldset css={{ gridColumnEnd: 'span 2' }}>
                <input
                  {...form.register('email', { ...validation.required(), ...validation.email() })}
                  type="email"
                  defaultValue={props.defaultEmail}
                  placeholder="&nbsp;"
                />
                <label>{t('auth.login_modal.email_label')}</label>
              </Fieldset>
            </FormFieldset>

            {(!props.forceAuth || isLogin) && (
              <FormFieldset form={form}>
                <Fieldset css={{ gridColumnEnd: 'span 2' }}>
                  <PasswordInput
                    {...form.register('password', validation.required())}
                    autoFocus={false}
                    autoComplete="new-password"
                    placeholder="&nbsp;"
                  />
                  <label>
                    {isLogin
                      ? t('auth.login_modal.password_label_signin')
                      : t('auth.login_modal.password_label_signup')}
                  </label>
                </Fieldset>
              </FormFieldset>
            )}
          </InputGrid>

          {!config?.onlyLogin && (
            <Text>
              {isLogin
                ? t('auth.login_modal.login_to_signup_text')
                : t('auth.login_modal.signup_to_login_text')}{' '}
              <PrimaryLink
                // for some reason when href is not specified, more than half of the times, after clicking the button on Android Chrome,
                // the onClick is not trigerred at all and instead the user is scrolled to the top
                href="#"
                onClick={(ev) => {
                  // but we don't want hashtag to be appended to the url, so we prevent that by using preventDefault()
                  ev.preventDefault()

                  setLogin(!isLogin)
                }}
              >
                {isLogin
                  ? t('auth.login_modal.signup_page_link')
                  : t('auth.login_modal.login_page_link')}
              </PrimaryLink>
            </Text>
          )}

          <SButtonContainer>
            <Button size="large" loading={form.formState.isSubmitting} type="submit">
              {(isLogin ? config?.loginButtonTitle : config?.signupButtonTitle) ||
                config?.buttonTitle ||
                t('auth.login_modal.submit_button')}
            </Button>
          </SButtonContainer>

          {!isLogin && (
            <div>
              <STermsText>
                <Trans
                  t={tCommon}
                  i18nKey={'common.signup.you_agree_notice'}
                  components={[<TermsAndPrivacyLinks key="0" />]}
                />
              </STermsText>
            </div>
          )}
        </form>
      </SAuthModal>
    </>
  )
}

export type AuthWithModalFn = (
  onSuccess: () => void,
  opts?: { defaultEmail?: string; mfaToken?: string }
) => Promise<void>

export const useAuthModal = (props?: {
  onLogin?: () => void
  onSignup?: () => void
  onCancel?: () => void
  closeOnSuccess?: boolean
  forceAuth?: boolean
  config?: AuthModalConfig
}) => {
  const [isMfa, setIsMfa] = useState(false)
  const [hideMfaBack, setHideMfaBack] = useState(false)
  const [onLoggedIn, setOnLoggedIn] = useState<() => void>()
  const [defaultEmail, setDefaultEmail] = useState<string>()
  const { mutateAuth, isLogged } = useAuth()
  const mfaToken = useMfaToken()

  const [authModal, showAuthModal, closeAuthModal] = useModal(
    ({ isOpen }) =>
      isOpen ? (
        isMfa ? (
          <MfaModal
            onBack={() => setIsMfa(false)}
            onLogin={() => {
              props?.onLogin?.()
              void mutateAuth()
            }}
            hideBack={hideMfaBack}
          />
        ) : (
          <AuthModal
            onMfa={() => setIsMfa(true)}
            onLogin={props?.onLogin}
            onSignup={props?.onSignup}
            defaultEmail={defaultEmail}
            forceAuth={props?.forceAuth}
            config={props?.config}
            mutateAuth={mutateAuth}
          />
        )
      ) : null,
    {
      modalCss: css`
        @media ${BREAKPOINTS.MD.min} {
          max-width: ${pxToRem(isMfa ? 750 : 548)}rem;
        }
      `,
      onClose: props?.onCancel,
      isNotClosable: !!props?.forceAuth,
    }
  )

  useEffect(() => {
    if (isLogged && onLoggedIn) {
      onLoggedIn()
    }
  }, [isLogged, onLoggedIn])

  const authWithModal: AuthWithModalFn = async (onSuccess, opts) => {
    if (isLogged) {
      await onSuccess()
    } else {
      setOnLoggedIn(() => () => {
        if (props?.closeOnSuccess) {
          closeAuthModal()
        }
        onSuccess()
      })
      setDefaultEmail(opts?.defaultEmail)
      if (opts?.mfaToken) {
        mfaToken.mutate(opts.mfaToken)
        setIsMfa(true)
        setHideMfaBack(true)
      }
      showAuthModal()
    }
  }

  return [authModal, authWithModal, closeAuthModal] as const
}
