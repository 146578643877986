import { SInputGrid } from './AuthSocial.styled'
import { Button } from 'components/button/Button'
import { ConditionalWrapper } from 'components/ConditionalWrapper'
import { Icon } from 'components/icon'
import { Stack } from 'components/Stack'
import { useTranslation } from 'next-i18next'
import { useState } from 'react'
import FacebookLogin from '@greatsumini/react-facebook-login'
import GoogleLogin from 'react-google-login'
import {
  FacebookFields,
  FacebookScope,
  GoogleScope,
  IAuthSocialProps,
  isGoogleLoginResponse,
} from 'utils/social'
import { pxToRemUnit } from 'utils/styled'
import { FACEBOOK_APP_ID } from 'pages/signup/lead-account/about-you'

export const AuthSocial = ({
  onFacebook,
  onGoogleSuccess,
  onGoogleFailure,
  block,
  areButtonsSignup,
}: IAuthSocialProps) => {
  const { t } = useTranslation()

  const [facebookLoading, setFacebookLoading] = useState(false)
  const [googleLoading, setGoogleLoading] = useState(false)

  return (
    <Stack space={10}>
      <SInputGrid disableMobileLayout columns={block ? 1 : undefined}>
        <ConditionalWrapper
          condition={!!block}
          wrapper={(children) => (
            <Stack css={{ flexDirection: 'column-reverse', gap: '0.625rem' }}>{children}</Stack>
          )}
        >
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            onSuccess={async (userInfo) => {
              try {
                await onFacebook(userInfo)
              } finally {
                setFacebookLoading(false)
              }
            }}
            scope={FacebookScope}
            fields={FacebookFields}
            render={(renderProps) => (
              <Button
                variant="secondary"
                size={['medium', 'medium', 'large']}
                css={{ minHeight: pxToRemUnit(54) }}
                stickyIcon={[false, true, true]}
                leftIcon={<Icon name="ColorFacebook" />}
                block
                tone="dark"
                loading={facebookLoading}
                disabled={!renderProps}
                type="button"
                onClick={() => {
                  setFacebookLoading(true)
                  renderProps.onClick?.()
                }}
              >
                {areButtonsSignup ? t('common.signup_with_facebook') : t('common.facebook')}
              </Button>
            )}
          />
          <GoogleLogin
            clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID as string}
            render={(renderProps) => (
              <Button
                variant="secondary"
                size={['medium', 'medium', 'large']}
                css={{ minHeight: pxToRemUnit(54) }}
                stickyIcon={[false, true, true]}
                leftIcon={<Icon name="ColorGoogle" />}
                block
                tone="dark"
                loading={googleLoading}
                disabled={renderProps.disabled}
                type="button"
                onClick={() => {
                  setGoogleLoading(true)
                  renderProps.onClick()
                }}
              >
                {areButtonsSignup ? t('common.signup_with_google') : t('common.google')}
              </Button>
            )}
            buttonText="Login"
            onSuccess={async (e) => {
              try {
                if (isGoogleLoginResponse(e)) {
                  await onGoogleSuccess?.(e)
                }
              } finally {
                setGoogleLoading(false)
              }
            }}
            onFailure={(e) => {
              setGoogleLoading(false)
              onGoogleFailure?.(e)
            }}
            cookiePolicy={'single_host_origin'}
            scope={GoogleScope}
          />
        </ConditionalWrapper>
      </SInputGrid>
    </Stack>
  )
}
