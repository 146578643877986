import { BREAKPOINTS, pxToRem } from 'utils/styled'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'
import { Text } from 'components/Typography'

export const SMfaModal = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  & > p {
    margin-bottom: ${pxToRem(18)}rem;
  }
`

export const SAuthModal = styled(motion.div)`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  & > p {
    color: var(--c-gray500);
  }
`

export const SAuthHeading = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-bottom: 2rem;

  & > svg,
  p:not(:last-child) {
    margin-bottom: ${pxToRem(24)}rem;
  }
`

export const SMfaHeading = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${pxToRem(12)}rem;
  & > svg {
    margin-right: ${pxToRem(12)}rem;
  }
`

export const SButtonContainer = styled.div`
  position: sticky;
  display: flex;
  justify-content: flex-end;

  left: 0;
  right: 0;
  bottom: 0.2rem;
  @supports (bottom: env(safe-area-inset-bottom)) {
    bottom: calc(env(safe-area-inset-bottom) + 0.2rem);
    transition: bottom 0.5s;
  }

  width: 100%;
  margin-top: 1.5rem;

  & > button {
    min-width: 25%;
  }

  @media ${BREAKPOINTS.MD.max} {
    & > button {
      flex: 1;
    }
  }
`

export const STermsText = styled(Text)`
  margin-top: 1.25rem;
  width: 100%;
  font-size: 0.8125rem;
  text-align: center;
`
